<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="9">
                <v-btn @click="openEditForm(null)" v-if="canEdit">Mitarbeiter hinzufügen</v-btn>
                <v-btn class="ml-5" @click="openAddRankDialog" v-if="canEdit">Rang hinzufügen</v-btn>
                <EmployeeForm @updateEmployeeList="fetchEmployee" :companies="companies" :departments="departments" :ranks="ranks" :licenses="licenses" ref="employeeFormRef"/>
                <v-btn class="ml-5" @click="showSortingModal = true">Ränge sortieren</v-btn>
                <v-btn class="ml-5" @click="showPreview = !showPreview">Vorlage {{ showPreview ? "deaktivieren" : "aktivieren" }}</v-btn>
                <v-spacer></v-spacer>
            </v-col>
            <v-col cols="3"> 
                <v-switch
                v-model="showIsTerminated"
                hide-details
                inset
                :label="`Gekündigt: ${showIsTerminated.toString()}`"
                class="ml-auto align-self-center"
                style="position: absolute; right: 30px;"
                ></v-switch>
            </v-col>
        </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            label="Search"
            dark
            outlined
            hide-details
            @input="updateFilteredItems"
          />
        </v-col>
        <v-col>
            <v-select
                v-model="selectedDepartment"
                :items="JobTypes"
                item-title="name"
                item-value="id"
                label="Department"
                dark
                outlined
                hide-details
                @change="filterByDepartment(selectedDepartment)"
            />
        </v-col>
      </v-row>
      <template v-for="(rank, index) in ranksWithEmployee" :key="index">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <template v-if="editingField.type !== 'rankName' || editingField.id !== (rank ? rank.id : -1)">
                <h2 @dblclick="startEditingField('rankName', rank ? rank.id : -1)">
                    {{ rank ? rank.name : '' }}
                    <v-btn icon size="small" v-if="rank.employees.length == 0" @click="openDeleteRankDialog(rank)" class="ml-2">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </h2>
            </template>
            <template v-else>
                <v-text-field
                    v-model="editedValue"
                    single-line
                     v-if="canEdit"
                    @keydown.enter="updateField(rank ? rank.id : -1)"
                    @keydown.esc="cancelEditingField"
                    @blur="cancelEditingField"
                    ref="editField"
                    class="mt-5 mr-5"
                ></v-text-field>
            </template>
          </v-col>
        </v-row>
  
        <v-row>
          <MemberCard
            v-for="(employee, employeeIndex) in rank.employees.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))"
            :key="employeeIndex"
            :member="employee"
            :logoURL="rank.rankImage"
            :companies="companies"
            :departments="departments"
            :trainings="Trainings"
            :trainingassigns="TrainingAssigns"
            :ranks="ranks"
            :licenses="licenses"
            :showPreview="showPreview"
            :showIsTerminated="showIsTerminated"
            @edit="openEditForm"
            @updateNotes="updateNotes"
          >
          </MemberCard>
        </v-row>
        <v-divider class="border-opacity-50 mt-12 mb-12"></v-divider>
      </template>



        <v-dialog v-model="deleteRankDialog" max-width="350px">
            <v-card>
                <v-card-title class="text-h5">Rang löschen</v-card-title>
                <v-card-text>
                Möchten Sie den Rang {{ rankToDelete.value ? rankToDelete.value.name : '' }} wirklich löschen?
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteRankDialog = false">Abbrechen</v-btn>
                <v-btn text @click="deleteRank">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="showSortingModal" max-width="600px">
            <v-card>
                <v-card-title>
                Kategorien sortieren
                </v-card-title>
                <v-card-text>
                    <draggable v-model="ranks" :list="ranks" tag="transition-group" :animation="150">
                        <template v-slot:item="{ element }">
                        <v-list-item :key="element.id" class="list-item mb-2 d-flex align-center" style="cursor: move;">
                            <v-avatar size="24" class="me-2">
                            <v-icon>mdi-drag-variant</v-icon>
                            </v-avatar>
                            <span>{{ element.name }}</span>
                        </v-list-item>
                        </template>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="showSortingModal = false">Abbrechen</v-btn>
                    <v-btn @click="saveCategorySorting">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addRankDialog" width="500">
            <v-card>
            <v-card-title>Neue Kategorie hinzufügen</v-card-title>
            <v-card-text>
                <v-form ref="addRankForm">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field label="Name" required v-model="newRank.name" @keydown.enter="openAddRankDialog"></v-text-field>
                        <v-select
                            v-model="newRank.department"
                            label="Select"
                            :items="JobTypes"
                            item-title="name"
                            item-value="id"
                        ></v-select>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="addRankDialog = false">Abbrechen</v-btn>
                <v-btn text @click="addNewRank">Hinzufügen</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted } from "vue";
  import MemberCard from "@/components/cards/MemberCard.vue";
  import EmployeeForm from "@/components/cards/EmployeeForm.vue";
  import { Rank, Company, Department, License } from "@/types/Members";
  import api from "@/api";
  import draggable from 'vuedraggable'
  import { JobTypes } from "@/types/Members";
  import { TrainingAssign, Training } from  "@/types/Training"
  import { useRoute } from "vue-router";
  
  export default defineComponent({
        components: {
            MemberCard,
            EmployeeForm,
            draggable,
        },
        setup() {
            const errorSnackbar = ref<any>({ visible: false, message: "" });
            const headers = ref([
                { text: "Name", value: "name" },
                { text: "Phone", value: "phone" },
            ]);
            const showPreview = ref(false);
            const search = ref("");
            const sortBy = ref({ text: "Name", value: "name" });
            const companies = ref<Company[]>([]);
            const departments = ref<Department[]>([]);
            const ranks = ref<Rank[]>([]);
            const licenses = ref<License[]>([]);
            const employeeFormRef = ref(null);
            const showSortingModal = ref(false);
            const showIsTerminated = ref(false);
            const Trainings = ref<Training[]>([]);
            const TrainingAssigns = ref<TrainingAssign[]>([]);

            
            const route = useRoute();

            const canEdit = computed(() => route.meta.canEdit);
            const canDelete = computed(() => route.meta.canDelete);

            const editedValue = ref('');
            const editingField = ref({ type: '', id: -1 });

            const addRankDialog = ref(false);
            const newRank = ref({
                name: "",
                department: 1,
            });

            const newEmployee = ref({
                name: '',
                personalid: '',
                phonenumber: '',
                mail: '',
                servicenumber: '',
                entrydate: '',
                bankaccount: '',
                leavedate: '',
                sidejob: '',
                company: [],
                department: [],
                rank: '',
                license: [],
                companies: [],
                departments: [],
                licenses: [],
                is_terminated: false,
                // Fügen Sie hier weitere Felder hinzu, falls erforderlich
            });

            const JobTypes = ref<JobTypes[]>([]);
            async function fetchJobTypes() {
                try {
                    const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                    JobTypes.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                    JobTypes.value = [];
                }
            }
        
            onMounted(async () => {
                await fetchJobTypes();
                await fetchEmployee();
                await fetchCompanies();
                await fetchDepartments();
                await fetchRanks();
                await fetchLicenses();
                await fetchTrainingAssigns();
                await fetchTrainings();
            });

            const idsToArray = (obj) => {
                return Object.keys(obj).map((key) => {
                    return key;
                });
            };



            const saveCategorySorting = async () => {
                try {
                    console.log("Sending category data:", ranks.value);
                    const response = await api.post("employee/?action=saveCategorySorting", JSON.stringify(ranks.value));

                    if (response.data.success) {
                        console.log("Category sorting saved successfully");
                        fetchEmployee();
                        fetchRanks();
                    } else {
                    console.error("Failed to save category sorting:", response.data.error);
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }

                showSortingModal.value = false;
            };

            const employeeData = ref<Rank[]>([]);
            const selectedDepartment = ref(1);

            const ranksWithEmployee = computed(() => {
                const currentDate = new Date();  // das heutige Datum
                currentDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                
                if (selectedDepartment.value == 0) {
                    return employeeData.value.map(rank => ({
                    ...rank,
                    employees: rank.employees.filter(employee => employee.is_terminated == false)
                    }));
                } else {
                    if(showIsTerminated.value){
                    return employeeData.value
                        .filter(rank => rank.jobrole_id == selectedDepartment.value)
                        .map(rank => ({
                            ...rank,
                            employees: rank.employees.filter(employee => {
                                const leaveDate = new Date(employee.leavedate);  // das Leave-Datum des Mitarbeiters
                                leaveDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                                return employee.is_terminated == true && currentDate > leaveDate;
                            })
                        }));
                    } else {
                    return employeeData.value
                        .filter(rank => rank.jobrole_id == selectedDepartment.value)
                        .map(rank => ({
                        ...rank,
                        employees: rank.employees.filter(employee => {
                                const leaveDate = new Date(employee.leavedate);  // das Leave-Datum des Mitarbeiters
                                leaveDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                                return employee.is_terminated == false || currentDate <= leaveDate;
                            })
                        }));
                    }
                }
            });






            const openEditForm = (data) => {
                console.log(data)
                if (data != null) {
                    data.employee.company = data.employee.companies ? idsToArray(data.employee.companies) : [];
                    data.employee.department = data.employee.departments ? idsToArray(data.employee.departments) : [];
                    data.employee.license = data.employee.licenses ? idsToArray(data.employee.licenses) : [];
                    employeeFormRef.value.openDialog(data.employee, false);
                    employeeFormRef.value.companies.value = companies.value;
                    employeeFormRef.value.departments.value = departments.value;
                    employeeFormRef.value.ranks.value = ranks.value;
                    employeeFormRef.value.licenses.value = licenses.value;
                } else {
                    employeeFormRef.value.openDialog(newEmployee, true);
                }
            };

            const updateNotes = (data) => {
                if (data != null) {
                    console.log(data.id);
                    console.log(data.notes);

                    ranksWithEmployee.value.forEach(ranksWithEmployee => {
                        ranksWithEmployee.employees.forEach(employee => {
                            if(employee.id == data.id){
                                employee.notes = data.notes;
                            }
                        })
                    })                    
                }
            }


            const startEditingField = (fieldType: string, fieldId: number) => {
                editingField.value = { type: fieldType, id: fieldId };
                if (fieldType === 'rankName' && ranksWithEmployee.value) {
                    const ranks = ranksWithEmployee.value.find(rank => rank.id === fieldId);
                    if (ranks) {
                        editedValue.value = ranks.name;
                    }
                }
                // Füge hier weitere Bedingungen für andere Feldtypen hinzu
            };

            const cancelEditingField = () => {
                editingField.value = { type: '', id: -1 };
            };

            async function updateField(fieldId: number) {
                if (editedValue.value.trim() === '') return;

                if (editingField.value.type === 'rankName' && ranksWithEmployee.value) {
                    try {
                        const ranks = ranksWithEmployee.value.find(rank => rank.id === fieldId);
                        if (!ranks) {
                            return;
                        }

                        await api.post('employee/?action=updateRankName', {
                            id: fieldId,
                            name: editedValue.value,
                        });

                        ranks.name = editedValue.value;
                    } catch (error) {
                        errorSnackbar.value.message = error.response.data.error;
                        errorSnackbar.value.visible  = true;
                    }
                }

                // Füge hier weitere Bedingungen für andere Feldtypen hinzu

                cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
            }




            async function fetchCompanies() {
                try {
                    const response = await api.post('employee/?action=getCompanies');
                    companies.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchDepartments() {
                try {
                    const response = await api.post('employee/?action=getDepartments');
                    departments.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchRanks() {
                try {
                    const response = await api.post('employee/?action=getRanks');
                    ranks.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchLicenses() {
                try {
                    const response = await api.post('employee/?action=getLicenses');
                    licenses.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchTrainingAssigns() {
                try {
                    const response = await api.get('training/index.php?action=getTrainingAssigns');
                    TrainingAssigns.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchTrainings() {
                try {
                    const response = await api.get('training/index.php?action=getTrainings');
                    Trainings.value = response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                        catId: item.catId,
                        cat_name: item.cat_name,
                        cat_short: item.cat_short,
                        color: item.color,
                    }));
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        
            async function fetchEmployee() {
                try {
                    const response = await api.post("employee/?action=getEmployee");
                    employeeData.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        
            const updateFilteredItems = () => {
                // No need to implement this function since filtering is now done in the template
            };

            function openAddRankDialog() {
                addRankDialog.value = true;
            }

            async function addNewRank() {
                try {
                    await api.post("employee/?action=addRank", newRank.value);
                    fetchRanks(); // Refresh Ranks after adding the new template+
                    fetchEmployee();
                    addRankDialog.value = false;
                    newRank.value.name = "";
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            function filterByDepartment(department: number) {
                selectedDepartment.value = department;
            }


            const deleteRankDialog = ref(false);
            const rankToDelete = ref(null);

            const openDeleteRankDialog = (rank) => {
                rankToDelete.value = rank;
                deleteRankDialog.value = true;
            };
            const deleteRank = async () => {
                try {
                    const response = await api.post("employee/?action=deleteRank", { id: rankToDelete.value.id });
                    
                    if (response.data.success) {
                    // Aktualisieren Sie die Rangliste, nachdem der Rang erfolgreich gelöscht wurde
                        fetchRanks();
                        employeeData.value = employeeData.value.filter(rank => rank.id !== rankToDelete.value.id);
                    } else {
                    // Fehlerbehandlung, falls das Löschen des Rangs nicht erfolgreich war
                    console.error("Error deleting rank:", response.data.message);
                    }

                    deleteRankDialog.value = false;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            };



        
            return {
                headers,
                search,
                sortBy,
                updateFilteredItems,
                companies,
                departments,
                ranks,
                licenses,
                ranksWithEmployee,
                openEditForm,
                employeeFormRef,
                fetchEmployee,
                editingField,
                editedValue,
                startEditingField,
                cancelEditingField,
                updateField,
                showSortingModal,
                saveCategorySorting,
                newRank, 
                addRankDialog,
                openAddRankDialog,
                addNewRank,
                selectedDepartment,
                filterByDepartment,
                showIsTerminated,
                JobTypes,
                deleteRankDialog,
                rankToDelete,
                openDeleteRankDialog,
                deleteRank,
                errorSnackbar,
                showPreview,
                updateNotes,
                Trainings,
                TrainingAssigns,
                canEdit,
                canDelete
            };
        },
  });
  </script>
  