import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (!_ctx.newReportDialog && !_ctx.editReportDialog && _ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _ctx.openCategorySelectionDialog,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Neuer Bericht")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterReports('all'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Alle (" + _toDisplayString(_ctx.reportCounts.all) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterReports('own'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Eigene (" + _toDisplayString(_ctx.reportCounts.own) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 3,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterReports('missing'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Fehlende Berichtergänzungen (" + _toDisplayString(_ctx.reportCounts.missing) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 4,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterReports('incomplete'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Alle nichtvollständige (" + _toDisplayString(_ctx.reportCounts.incomplete) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 5,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterReports('open'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Offene Berichte (" + _toDisplayString(_ctx.reportCounts.open) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 6,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterReports('pending'))),
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Ausstehende Prüfungen (" + _toDisplayString(_ctx.reportCounts.pending) + ")", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.newReportDialog && !_ctx.editReportDialog)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 7,
            headers: _ctx.reportHeaders,
            items: _ctx.filteredReports,
            class: "elevation-1 mt-2",
            search: _ctx.search,
            "items-per-page": 25
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Berichtverwaltung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.filters.category_id,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.category_id) = $event)),
                    items: _ctx.categories,
                    "item-title": "name",
                    "item-value": "id",
                    label: "Filter nach Abteilung",
                    clearable: "",
                    variant: "underlined",
                    class: "mx-4 mt-3"
                  }, null, 8, ["modelValue", "items"]),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.filters.creator,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.creator) = $event)),
                    items: _ctx.employees,
                    "item-title": "display_name",
                    "item-value": "id",
                    label: "Filter nach Ersteller",
                    clearable: "",
                    variant: "underlined",
                    class: "mx-4 mt-3"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _: 1
              })
            ]),
            [`item.report_info`]: _withCtx(({ item }) => [
              _createElementVNode("span", {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openEditReportDialog(item)),
                style: {"cursor":"pointer"}
              }, [
                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                (item.missing_employees.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(fehlende Aussagen: " + _toDisplayString(item.missing_employees.length) + ")", 1))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_1)
            ]),
            [`item.approved`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.pinned == 1 ? "📌 " : "") + _toDisplayString(item.approved == 0 ? "🔴" : "🟢"), 1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openEditReportDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDeleteReportDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.downloadReportPdf(item.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-download")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.pinReport(item.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.pinned == 1 ? "mdi-pin-off" : "mdi-pin"), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["headers", "items", "search"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.categorySelectionDialog,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.categorySelectionDialog) = $event)),
        "max-width": "400"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Kategorie auswählen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    label: "Kategorie",
                    modelValue: _ctx.selectedCategory,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                    items: _ctx.categories,
                    "item-title": "name",
                    "item-value": "id",
                    required: "",
                    rules: [_ctx.requiredRule]
                  }, null, 8, ["modelValue", "items", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.categorySelectionDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.createNewReport
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Weiter")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.AuthorityAddReportDialog), _mergeProps(_ctx.addReportProps, {
        modelValue: _ctx.newReportDialog,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newReportDialog) = $event)),
        selectedCategory: _ctx.selectedCategory,
        newReportDialog: _ctx.newReportDialog,
        onReportAdded: _ctx.onReportAdded,
        onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.closeEditReportDialog()))
      }), null, 16, ["modelValue", "selectedCategory", "newReportDialog", "onReportAdded"])),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.AuthorityEditReportDialog), _mergeProps(_ctx.editReportProps, {
        modelValue: _ctx.editReportDialog,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editReportDialog) = $event)),
        reportToEdit: _ctx.editedReport,
        onReportUpdated: _ctx.onReportUpdated,
        onClose: _cache[15] || (_cache[15] = ($event: any) => (_ctx.closeEditReportDialog()))
      }), null, 16, ["modelValue", "reportToEdit", "onReportUpdated"])),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteReportDialog,
        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.deleteReportDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Bericht löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie den Bericht \"" + _toDisplayString(_ctx.selectedReport?.title) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.deleteReportDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.deleteReport(_ctx.selectedReport)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}