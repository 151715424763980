import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed5ad658"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, {
      fluid: "",
      class: "fill-height"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "center",
          justify: "center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "6",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_img, {
                    src: "/img/logo.png",
                    "max-height": "150",
                    contain: "",
                    class: "logo"
                  })
                ]),
                _createVNode(_component_v_card, {
                  class: "rounded-xl",
                  style: {"opacity":"0.9"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, {
                      "prepend-icon": "mdi-home",
                      style: {"height":"50px"}
                    }),
                    _createVNode(_component_v_card_text, { class: "mt-8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_form, {
                          ref: "form",
                          onSubmit: _withModifiers(_ctx.submit, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Benutzername",
                              modelValue: _ctx.username,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
                              rules: [_ctx.required],
                              required: "",
                              "prepend-icon": "mdi-account-outline"
                            }, null, 8, ["modelValue", "rules"]),
                            _createVNode(_component_v_text_field, {
                              label: "Passwort",
                              modelValue: _ctx.password,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                              type: "password",
                              rules: [_ctx.required],
                              required: "",
                              "prepend-icon": "mdi-lock-outline"
                            }, null, 8, ["modelValue", "rules"]),
                            _createVNode(_component_v_select, {
                              label: "System auswählen",
                              items: [
                                /*{ text: 'Test System', value: 'test' },*/
                                { text: 'FireGuard Solutions', value: 'fireguard' },
                               /* { text: 'Los Santos Fire Department', value: 'fire' },
                                { text: 'Los Santos Medical Department', value: 'medical' },
                                { text: 'San Andreas Police Department', value: 'police' },
                                { text: 'San Andreas State Park', value: 'statepark' },
                                { text: 'City Administration San Andreas', value: 'casa' },
                                { text: 'Department of Justice', value: 'justice' },*/
                            ],
                              "item-title": "text",
                              "item-value": "value",
                              "prepend-icon": "mdi-domain",
                              modelValue: _ctx.authority,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.authority) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_checkbox, {
                              label: "Automatisch eingeloggt bleiben",
                              modelValue: _ctx.autoLogin,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.autoLogin) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_card_actions, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  variant: "tonal",
                                  type: "submit",
                                  class: "mt-n8 flex-grow-1",
                                  size: "large",
                                  height: "48"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Anmelden")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onSubmit"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}